@import url(~normalize.css/normalize.css);
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%; }

body {
  margin: 0;
  padding: 0;
  color: #242424;
  font-family: -apple-system-body, BlinkMacSystemFont,SFUI, 'Open Sans', Helvetica, Arial, sans-serif; }

img {
  max-width: 100%; }

#acute-outer-container {
  position: absolute;
  height: 700px;
  bottom: calc(50% - 700px/2);
  right: 40px;
  width: 100%; }
  @media screen and (max-width: 414px) {
    #acute-outer-container {
      width: calc(95% - 40px); } }
  @media screen and (max-height: 710px) {
    #acute-outer-container {
      bottom: 2.5%;
      height: 95%; } }
  @media screen and (max-height: 569px) {
    #acute-outer-container {
      height: 97%;
      bottom: 1.5%; } }
  @media screen and (min-width: 1700px) {
    #acute-outer-container {
      max-height: 950px;
      height: 950px;
      bottom: calc(50% - 950px/2); } }

#acute-ui {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  #acute-ui .aui-widget-container--active {
    height: 100%; }
  #acute-ui .aui-widget-container {
    width: 25.5em;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    #acute-ui .aui-widget-container:before {
      content: '';
      position: fixed;
      bottom: 0;
      right: 0;
      width: 500px;
      height: 500px;
      z-index: 0; }
    @media screen and (max-width: 414px) {
      #acute-ui .aui-widget-container {
        width: 100%; } }
  #acute-ui .aui-widget-container.widget-with-text {
    padding-right: 5px; }
  #acute-ui .aui-modal-container--active {
    height: 100%;
    margin: auto;
    padding: 1em;
    position: relative;
    right: -40px; }
  #acute-ui .aui-modal-container {
    width: 690px; }
    @media all and (max-width: 767px) {
      #acute-ui .aui-modal-container {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0; } }

.aui-add-active-state, .aui-button {
  transition: box-shadow .1s ease-in; }
  .aui-add-active-state:hover, .aui-button:hover, .aui-add-active-state:focus, .aui-button:focus, .aui-add-active-state:active, .aui-button:active {
    box-shadow: 0 0 0 2px rgba(48, 35, 174, 0.3); }

.aui-wrapper {
  position: relative;
  height: 100%;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16) !important;
  border-radius: 8px; }

ul {
  margin: 0;
  padding: 0; }

p {
  margin: 0 0 10px 0;
  padding: 0; }

a:hover {
  text-decoration: none !important; }

:hover {
  outline: none; }

.external-link {
  color: unset;
  text-decoration: underline; }
  .external-link:hover {
    color: #000;
    text-decoration: underline !important; }
