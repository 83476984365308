.aui-modal {
  z-index: 2;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.aui-modal__overlay {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 8px; }

.aui-modal__content {
  background: #fff;
  z-index: 3;
  border-radius: 3px;
  width: 100%;
  max-width: 25em; }
