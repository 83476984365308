@import url(https://fonts.googleapis.com/css?family=Open+Sans);
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%; }

body {
  margin: 0;
  padding: 0;
  color: #242424;
  font-family: -apple-system-body, BlinkMacSystemFont,SFUI, 'Open Sans', Helvetica, Arial, sans-serif; }

img {
  max-width: 100%; }

#acute-outer-container {
  position: absolute;
  height: 700px;
  bottom: calc(50% - 700px/2);
  right: 40px;
  width: 100%; }
  @media screen and (max-width: 414px) {
    #acute-outer-container {
      width: calc(95% - 40px); } }
  @media screen and (max-height: 710px) {
    #acute-outer-container {
      bottom: 2.5%;
      height: 95%; } }
  @media screen and (max-height: 569px) {
    #acute-outer-container {
      height: 97%;
      bottom: 1.5%; } }
  @media screen and (min-width: 1700px) {
    #acute-outer-container {
      max-height: 950px;
      height: 950px;
      bottom: calc(50% - 950px/2); } }

#acute-ui {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  #acute-ui .aui-widget-container--active {
    height: 100%; }
  #acute-ui .aui-widget-container {
    width: 25.5em;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    #acute-ui .aui-widget-container:before {
      content: '';
      position: fixed;
      bottom: 0;
      right: 0;
      width: 500px;
      height: 500px;
      z-index: 0; }
    @media screen and (max-width: 414px) {
      #acute-ui .aui-widget-container {
        width: 100%; } }
  #acute-ui .aui-widget-container.widget-with-text {
    padding-right: 5px; }
  #acute-ui .aui-modal-container--active {
    height: 100%;
    margin: auto;
    padding: 1em;
    position: relative;
    right: -40px; }
  #acute-ui .aui-modal-container {
    width: 690px; }
    @media all and (max-width: 767px) {
      #acute-ui .aui-modal-container {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0; } }

.aui-add-active-state, .aui-button {
  -webkit-transition: box-shadow .1s ease-in;
  transition: box-shadow .1s ease-in; }
  .aui-add-active-state:hover, .aui-button:hover, .aui-add-active-state:focus, .aui-button:focus, .aui-add-active-state:active, .aui-button:active {
    box-shadow: 0 0 0 2px rgba(48, 35, 174, 0.3); }

.aui-wrapper {
  position: relative;
  height: 100%;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16) !important;
  border-radius: 8px; }

ul {
  margin: 0;
  padding: 0; }

p {
  margin: 0 0 10px 0;
  padding: 0; }

a:hover {
  text-decoration: none !important; }

:hover {
  outline: none; }

.external-link {
  color: unset;
  text-decoration: underline; }
  .external-link:hover {
    color: #000;
    text-decoration: underline !important; }

.aui-tags-wrapper {
  list-style-type: none; }
  .aui-tags-wrapper li {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    border-radius: 8px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 1px 6px;
    background: #c86dd7;
    color: #fff; }
    .aui-tags-wrapper li:last-child {
      margin-right: 0; }

.aui-card {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.05), 0 0 0 1px rgba(10, 10, 10, 0.05);
  font-size: 14px;
  color: #333;
  margin: 18px 2px 2px 2px;
  min-height: 98px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer; }
  .aui-card img {
    width: 16px;
    height: 16px;
    vertical-align: middle; }
  .aui-card:hover {
    box-shadow: 0 4px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1); }
  .aui-card .aui-card__votes {
    position: absolute;
    top: 0.9em;
    right: 10px;
    color: #ccc;
    border: 1px solid #e9e9e9;
    padding: 0.1em 0.6em;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid rgba(35, 35, 35, 0.2); }
    .aui-card .aui-card__votes:hover {
      color: #999; }
    .aui-card .aui-card__votes.active {
      color: #3023AE;
      border: 1px solid #3023AE; }
    .aui-card .aui-card__votes > * {
      display: block; }
  .aui-card .aui-card__content, .aui-card .aui-card__meta {
    padding: 0 10px; }
  .aui-card .aui-card__content {
    height: 100%;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    padding-right: 3.2em;
    margin: 20px 10px 10px 10px; }
    .aui-card .aui-card__content .aui-card__content_title {
      line-height: 1.2em;
      font-size: 16px;
      font-weight: 600; }
    .aui-card .aui-card__content .aui-card__tags-state .aui-card-state {
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      border-radius: 3px;
      padding: 2px 4px;
      background: #deebff;
      color: #0747a6; }
    .aui-card .aui-card__content .aui-card__tags-state-ur .aui-card-state-ur {
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      border-radius: 3px;
      padding: 2px 4px;
      background: #efddff;
      color: #7b26c5; }
  .aui-card .aui-card__meta {
    display: flex;
    flex-direction: row;
    margin: 0 10px 20px 10px; }
    .aui-card .aui-card__meta img {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      margin-right: 10px;
      margin-top: 2px; }
    .aui-card .aui-card__meta .aui-card__meta_tags {
      width: 100%;
      flex: 1 1; }
      .aui-card .aui-card__meta .aui-card__meta_tags .aui-tags-wrapper {
        margin-bottom: 5px; }
      .aui-card .aui-card__meta .aui-card__meta_tags p {
        font-size: 11px;
        line-height: 1;
        margin: 0; }

.aui-card__view-comments-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 72px;
  right: 13px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: .115s filter ease-in;
  transition: .115s filter ease-in; }
  .aui-card__view-comments-button .aui-card__total-comments {
    font-size: 14px;
    color: #9b98b3;
    line-height: 1;
    margin-top: -4px;
    margin-left: 4px;
    font-weight: 500; }
  .aui-card__view-comments-button:hover {
    -webkit-filter: saturate(20);
            filter: saturate(20); }

.aui-card-list {
  position: relative;
  padding: 0 10px;
  flex: 1 1;
  overflow: hidden;
  background: #f8f9fa; }
  .aui-card-list:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 10px;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(90%, #f8f9fa));
    background: -webkit-linear-gradient(transparent, #f8f9fa 90%);
    background: linear-gradient(transparent, #f8f9fa 90%); }
  .aui-card-list .bp3-non-ideal-state .bp3-heading {
    color: #ccc; }
  .aui-card-list .bp3-spinner {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px); }

.aui-card-list__main-action {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px 0 10px 0;
  background: #f8f9fa;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.aui__header {
  background: -webkit-linear-gradient(150deg, #3023AE, #C86DD7);
  background: linear-gradient(-60deg, #3023AE, #C86DD7);
  color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px; }

.aui-title {
  text-align: center; }

.aui-title__title {
  font-size: 0.9rem;
  color: #ffffffb3; }

.aui-header-close {
  font-size: 16px;
  font-weight: 800;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer; }

.aui-searchbar {
  position: relative;
  margin-bottom: 10px; }
  .aui-searchbar input {
    background: transparent;
    border: none;
    outline: none;
    width: calc(100% - 36px);
    margin: 0 18px;
    font-size: 16px;
    padding-right: 16px; }
    .aui-searchbar input, .aui-searchbar input::-webkit-input-placeholder {
      color: #fff; }
    .aui-searchbar input, .aui-searchbar input::-moz-placeholder {
      color: #fff; }
    .aui-searchbar input, .aui-searchbar input:-ms-input-placeholder {
      color: #fff; }
    .aui-searchbar input, .aui-searchbar input::-ms-input-placeholder {
      color: #fff; }
    .aui-searchbar input, .aui-searchbar input::placeholder {
      color: #fff; }
  .aui-searchbar svg {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    margin-top: -7px;
    right: 18px; }

.aui__modal__header .aui-searchbar {
  color: #ffffffb3; }

.aui__modal__header .aui-searchbar input {
  color: #fff; }

.aui__modal__header .aui-searchbar input::-webkit-input-placeholder {
  color: #ffffffb3; }

.aui__modal__header .aui-searchbar input::-moz-placeholder {
  color: #ffffffb3; }

.aui__modal__header .aui-searchbar input:-ms-input-placeholder {
  color: #ffffffb3; }

.aui__modal__header .aui-searchbar input::-ms-input-placeholder {
  color: #ffffffb3; }

.aui__modal__header .aui-searchbar input::placeholder {
  color: #ffffffb3; }

.aui-write-button {
  background: #fff;
  border: 1px solid #3023AE;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  min-width: 170px;
  font-weight: 600;
  line-height: 1;
  color: #3023AE;
  padding: 6px 12px;
  text-align: center; }
  .aui-write-button .aui-write-button__icon {
    fill: #3023AE;
    width: 14px;
    margin-right: 6px; }
  .aui-write-button svg,
  .aui-write-button span {
    vertical-align: middle; }
  .aui-write-button:hover {
    cursor: pointer; }

.aui-navbar {
  padding: .5em .5em 0;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin-bottom: 10px; }
  .aui-navbar li {
    width: 100%;
    flex: 1 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 40px;
    line-height: 40px;
    color: #fff; }
    .aui-navbar li .aui-navbar-button {
      border-radius: 20px;
      padding: 4px 8px; }
    .aui-navbar li.active .aui-navbar-button {
      background: #fff;
      color: #363636;
      font-weight: 500; }
    .aui-navbar li:hover:not(.active) .aui-navbar-button {
      background: rgba(0, 0, 0, 0.2); }
    .aui-navbar li:last-child {
      border-right: none; }

.aui-modal-navbar {
  justify-content: center; }
  .aui-modal-navbar li {
    width: unset;
    flex: 0 0 auto;
    margin: 0 1em;
    height: unset; }
    .aui-modal-navbar li .aui-navbar-button {
      border-radius: 20px;
      padding: 4px 12px;
      color: #ffffffb3; }

.aui-navbar-button {
  cursor: pointer;
  color: #fff;
  background: none;
  border: none;
  outline: none; }

.aui__footer-container {
  padding: 1em;
  text-align: center;
  border-top: 1px solid #eee;
  background: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }
  .aui__footer-container .emoji {
    font-size: 13.5px; }
  .aui__footer-container .message {
    font-size: 14px;
    color: rgba(162, 158, 184, 0.57); }
  .aui__footer-container .logo {
    height: 11px;
    margin-bottom: -1px; }

.aui-wrapper__requests {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 710px; }

.aui-modal-container .aui-wrapper__requests {
  max-height: unset; }

.aui-wrapper__in_progress {
  display: flex;
  flex-direction: column;
  height: 100%; }

.aui-wrapper__completed {
  display: flex;
  flex-direction: column;
  height: 100%; }

.aui-backbar {
  background: -webkit-linear-gradient(150deg, #3023AE, #C86DD7);
  background: linear-gradient(-60deg, #3023AE, #C86DD7);
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  min-height: 40px; }
  .aui-backbar .aui-backbar-button {
    padding: 10px;
    display: inline-block;
    cursor: pointer; }
  .aui-backbar a {
    color: inherit;
    display: flex;
    flex-direction: row;
    line-height: 40px; }
  .aui-backbar h3 {
    margin: 0;
    font-weight: 500;
    font-size: 16px; }

.aui-wrapper__new_card {
  height: 100%; }

.aui-wrapper__new_card-form {
  padding: 20px; }
  .aui-wrapper__new_card-form .error__container {
    color: #f26245;
    margin: 15px 5px; }
  .aui-wrapper__new_card-form .input__container {
    margin: 0 0 20px;
    padding: 8px 12px;
    border: 1px solid #ddd;
    cursor: text;
    box-sizing: border-box;
    border-radius: 5px; }
    .aui-wrapper__new_card-form .input__container input {
      width: 100%;
      border: 0;
      font-size: 15px; }
    .aui-wrapper__new_card-form .input__container input:focus {
      outline: none; }
    .aui-wrapper__new_card-form .input__container input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #fff inset; }
    .aui-wrapper__new_card-form .input__container textarea {
      width: 100%;
      border: 0;
      font-size: 15px;
      resize: none; }
    .aui-wrapper__new_card-form .input__container textarea:focus {
      outline: none; }
    .aui-wrapper__new_card-form .input__container textarea:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #fff inset; }
  .aui-wrapper__new_card-form .aui-wrapper__new_card-form-emoji-button {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.5; }
    .aui-wrapper__new_card-form .aui-wrapper__new_card-form-emoji-button:hover {
      cursor: pointer; }
    .aui-wrapper__new_card-form .aui-wrapper__new_card-form-emoji-button:hover:not(.active) {
      opacity: 0.75; }
    .aui-wrapper__new_card-form .aui-wrapper__new_card-form-emoji-button.active {
      opacity: 1.0; }
  .aui-wrapper__new_card-form .bp3-tag {
    background-color: #C86DD7; }
    .aui-wrapper__new_card-form .bp3-tag .bp3-fill {
      line-height: 1; }
  .aui-wrapper__new_card-form .bp3-form-group {
    margin-bottom: 20px;
    position: relative; }
    .aui-wrapper__new_card-form .bp3-form-group label {
      margin-bottom: 5px;
      font-size: 14px;
      color: #666; }
    .aui-wrapper__new_card-form .bp3-form-group textarea {
      width: 100%;
      max-height: 180px; }
    .aui-wrapper__new_card-form .bp3-form-group .bp3-input-group.bp3-large .bp3-input {
      font-size: 14px; }

.aui-wrapper__new_card-form__title {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 500; }

.aui-wrapper__new_card-form__subtitle {
  color: #666;
  margin-bottom: 30px;
  text-align: center; }

.aui-wrapper__new_card-actions {
  display: flex;
  justify-content: center; }

.aui-wrapper__new_card-actions__action {
  display: block;
  background: #3023AE;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  padding: 6px 12px;
  margin: 0 5px; }
  .aui-wrapper__new_card-actions__action:hover {
    color: #fff; }
  .aui-wrapper__new_card-actions__action.__secondary {
    background: #fff;
    border: 1px solid #3023AE;
    color: #3023AE; }

.aui-write-box {
  display: flex;
  flex-direction: column; }
  .aui-write-box .emoji-mart {
    position: absolute;
    bottom: 55px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .aui-write-box .aui-write-box__input_wrapper {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #eee; }
    .aui-write-box .aui-write-box__input_wrapper button {
      background: #3023AE;
      box-shadow: none;
      border-radius: 0;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      min-width: 80px;
      -webkit-transition: background .2s ease-in;
      transition: background .2s ease-in; }
      .aui-write-box .aui-write-box__input_wrapper button:hover {
        background: #3023AE; }
  .aui-write-box textarea {
    width: 100%;
    flex: 1 1;
    box-shadow: none;
    padding: 10px 10px 10px 20px;
    resize: none; }
    .aui-write-box textarea:active, .aui-write-box textarea:focus {
      box-shadow: none; }
  .aui-write-box .aui-write-box__emoji_button {
    position: absolute;
    bottom: 14px;
    left: 14px;
    opacity: 0.5; }
    .aui-write-box .aui-write-box__emoji_button.active {
      opacity: 1; }
    .aui-write-box .aui-write-box__emoji_button:hover {
      cursor: pointer; }
    .aui-write-box .aui-write-box__emoji_button:hover:not(.active) {
      opacity: 0.75; }

.aui-comment {
  padding: 10px 50px 0 15px; }
  .aui-comment .aui-comment__body {
    padding-top: 5px; }
    .aui-comment .aui-comment__body img {
      width: 16px;
      height: 16px;
      vertical-align: middle; }
    .aui-comment .aui-comment__body p {
      margin-top: 0;
      margin-bottom: 10px; }
  .aui-comment .aui-comment__author {
    display: flex;
    flex-direction: row;
    line-height: 32px; }
    .aui-comment .aui-comment__author img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px; }
    .aui-comment .aui-comment__author .aui-comment__author__img {
      position: relative; }
      .aui-comment .aui-comment__author .aui-comment__author__img .admin-badge {
        position: absolute;
        top: 20px;
        left: 18px;
        background: #fff;
        border-radius: 10px;
        color: #4304cc;
        border: 1px solid #fff; }
    .aui-comment .aui-comment__author .aui-comment__author__details {
      margin: 0;
      line-height: 1;
      color: #999;
      font-size: 12px;
      margin-bottom: 10px; }
      .aui-comment .aui-comment__author .aui-comment__author__details .aui-comment-author {
        margin-bottom: 0.5em;
        font-weight: 600; }
      .aui-comment .aui-comment__author .aui-comment__author__details .admin {
        color: #3023ae; }
  .aui-comment .aui-card-details__delete_button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 25px;
    right: 20px;
    color: #ccc;
    text-align: center;
    cursor: pointer;
    -webkit-transition: .115s filter ease-in;
    transition: .115s filter ease-in;
    z-index: 1; }
    .aui-comment .aui-card-details__delete_button:hover path {
      fill: rgba(174, 35, 35, 0.3); }

.aui-comment-list {
  height: 100%;
  flex: 1 1;
  padding: 0 10px; }
  .aui-comment-list .bp3-non-ideal-state .bp3-heading {
    color: #ccc; }

.aui-card-details__wrapper {
  background: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%; }
  .aui-card-details__wrapper .aui-card-details__comments {
    flex: 1 1; }
  .aui-card-details__wrapper .bp3-non-ideal-state .bp3-heading {
    color: #ccc; }

.aui-card-details__card {
  padding: 20px 60px 20px 20px;
  background: #fff;
  position: relative; }
  .aui-card-details__card img {
    width: 16px;
    height: 16px;
    vertical-align: middle; }
  .aui-card-details__card .aui-card__votes {
    position: absolute;
    top: 0.9em;
    right: 10px;
    color: #ccc;
    border: 1px solid #e9e9e9;
    padding: 0.1em 0.6em;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid rgba(35, 35, 35, 0.2); }
    .aui-card-details__card .aui-card__votes:hover {
      color: #999; }
    .aui-card-details__card .aui-card__votes.active {
      color: #3023AE !important;
      border: 1px solid #3023AE; }
    .aui-card-details__card .aui-card__votes > * {
      display: block; }

.aui-card-details__card_title {
  font-size: 16px;
  line-height: 1.2em;
  margin-bottom: 10px; }
  .aui-card-details__card_title p {
    font-weight: bold; }

.aui-card-details__delete_button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 72px;
  right: 20px;
  color: #ccc;
  text-align: center;
  cursor: pointer;
  -webkit-transition: .115s filter ease-in;
  transition: .115s filter ease-in;
  z-index: 1; }
  .aui-card-details__delete_button:hover path {
    fill: rgba(174, 35, 35, 0.3); }

.aui-card-details__card_author {
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  background: #fff;
  padding-bottom: 10px;
  position: relative; }
  .aui-card-details__card_author img {
    margin-right: 10px;
    border-radius: 50%; }
  .aui-card-details__card_author .admin-badge {
    position: absolute;
    top: 20px;
    left: 38px;
    background: #fff;
    border-radius: 10px;
    color: #4304cc;
    border: 1px solid #fff; }
  .aui-card-details__card_author .aui-card-details__card_tags {
    font-size: 12px;
    margin-bottom: 10px; }
    .aui-card-details__card_author .aui-card-details__card_tags .aui-tags-wrapper {
      margin-bottom: 4px; }
    .aui-card-details__card_author .aui-card-details__card_tags p {
      margin: 0;
      line-height: 1;
      color: #999; }
    .aui-card-details__card_author .aui-card-details__card_tags p.aui-card-author {
      margin-bottom: 0.5em;
      font-weight: 600; }
    .aui-card-details__card_author .aui-card-details__card_tags p.aui-card-author.admin {
      color: #4400CC; }

.aui-card-details__card_body {
  white-space: pre-wrap; }

.aui-card-details__comments {
  border-top: 1px solid #eee; }
  .aui-card-details__comments .aui-comment {
    background: #fff;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.05), 0 0 0 1px rgba(10, 10, 10, 0.05);
    border-radius: 8px;
    margin: 10px 2px 2px 2px; }

@charset "UTF-8";
.aui-widget-button-wrapper {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  bottom: calc(50% - 50px/2);
  right: -15px;
  position: fixed;
  z-index: 1;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: -webkit-linear-gradient(224deg, #3023AE 0%, #C86DD7 100%);
  background-image: linear-gradient(-134deg, #3023AE 0%, #C86DD7 100%);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  text-align: center; }
  .aui-widget-button-wrapper img {
    width: 23.80952px;
    margin-left: -1px; }
  .aui-widget-button-wrapper svg {
    fill: #fff;
    color: #fff;
    margin-left: -11px;
    width: 18px !important;
    height: 18px !important; }
  .aui-widget-button-wrapper:hover {
    cursor: pointer; }
  .aui-widget-button-wrapper--bottom-left {
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    bottom: 20px;
    left: 20px; }
  .aui-widget-button-wrapper--bottom-right {
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    bottom: 20px;
    right: 20px; }
  .aui-widget-button-wrapper--top-right {
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    top: 20px;
    right: 20px; }
  .aui-widget-button-wrapper--top-left {
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    top: 20px;
    left: 20px; }

.aui-widget-button-wrapper-with-text {
  right: -3px;
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: -webkit-linear-gradient(224deg, #3023AE 0%, #C86DD7 100%);
  background-image: linear-gradient(-134deg, #3023AE 0%, #C86DD7 100%);
  -webkit-transition: box-shadow 0.1s ease-in-out;
  transition: box-shadow 0.1s ease-in-out;
  padding: 12px 14px 12px 11px;
  border-radius: 3px 0 0 3px;
  text-align: center; }
  .aui-widget-button-wrapper-with-text img {
    width: 23.80952px;
    margin-left: -1px; }
  .aui-widget-button-wrapper-with-text svg {
    fill: #fff;
    color: #fff;
    margin: 10px 0 0 0;
    width: 14px !important;
    height: 14px !important; }
  .aui-widget-button-wrapper-with-text:hover {
    cursor: pointer;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 35px 2px rgba 0, 0, 0, 0.24;
    right: 0; }
  .aui-widget-button-wrapper-with-text--bottom-left {
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    bottom: 20px;
    left: 20px; }
  .aui-widget-button-wrapper-with-text--bottom-right {
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    bottom: 20px;
    right: 20px; }
  .aui-widget-button-wrapper-with-text--top-right {
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    top: 20px;
    right: 20px; }
  .aui-widget-button-wrapper-with-text--top-left {
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    top: 20px;
    left: 20px; }

.aui-widget-button-wrapper-with-text.is-open {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 35px 2px rgba 0, 0, 0, 0.24;
  right: 0; }
  .aui-widget-button-wrapper-with-text.is-open:hover {
    cursor: pointer;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 35px 2px rgba 0, 0, 0, 0.24;
    right: 0; }

.icon-hidden img {
  display: none !important; }

.icon-hidden svg {
  display: none !important; }

.aui-widget-button-label {
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  color: #fff;
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.aui-signupForm__container .aui-signupForm__content {
  padding: 2.1em 1.1em; }

.aui-signupForm__container .aui-signupForm__title {
  font-size: 1.19em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  line-height: 24px;
  text-align: center;
  font-weight: 500; }

.aui-signupForm__container .aui-signupForm__subtitle {
  color: #666;
  text-align: center;
  -webkit-margin-after: 1.19em;
          margin-block-end: 1.19em; }

.aui-signupForm__container .error__container {
  color: #f26245;
  margin: 15px 5px; }

.aui-signupForm__container .input__container {
  margin: 0 0 15px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  cursor: text;
  box-sizing: border-box;
  border-radius: 5px; }
  .aui-signupForm__container .input__container input {
    width: 100%;
    border: 0;
    font-size: 15px; }
  .aui-signupForm__container .input__container input:focus {
    outline: none; }
  .aui-signupForm__container .input__container input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset; }

.aui-signupForm__container .buttons__container {
  justify-content: center;
  display: flex;
  padding-top: 5px; }
  .aui-signupForm__container .buttons__container button {
    cursor: pointer;
    padding: 12px 14px;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 5px;
    background: #7645c0;
    color: #fff; }

.aui-signupForm__container .aui-signupForm__footer {
  border-top: 1px solid #efefef;
  text-align: center;
  padding: 15px 0;
  cursor: pointer; }
  .aui-signupForm__container .aui-signupForm__footer span {
    font-size: 15px;
    line-height: 22px; }

.aui-loginForm__container .aui-loginForm__content {
  padding: 2.1em 1.1em 0; }
  .aui-loginForm__container .aui-loginForm__content .error__container {
    color: #f26245;
    margin: 15px 5px; }

.aui-loginForm__container .aui-loginForm__title {
  font-size: 1.19em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  line-height: 24px;
  margin: 0 0 20px 5px;
  text-align: center;
  font-weight: 500; }

.aui-loginForm__container .input__container {
  margin: 0 0 15px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  cursor: text;
  box-sizing: border-box;
  border-radius: 5px; }
  .aui-loginForm__container .input__container input {
    width: 100%;
    border: 0;
    font-size: 15px; }
  .aui-loginForm__container .input__container input:focus {
    outline: none; }
  .aui-loginForm__container .input__container input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset; }

.aui-loginForm__container .buttons__container {
  text-align: center;
  padding: 0 0 10px; }
  .aui-loginForm__container .buttons__container span {
    display: block;
    margin-top: 10px;
    cursor: pointer; }
  .aui-loginForm__container .buttons__container button {
    cursor: pointer;
    padding: 12px 14px;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 5px;
    background: #7645c0;
    color: #fff; }

.aui-loginForm__container .aui-loginForm__footer {
  border-top: 1px solid #efefef;
  text-align: center;
  padding: 15px 0;
  cursor: pointer; }
  .aui-loginForm__container .aui-loginForm__footer span {
    font-size: 15px;
    line-height: 22px; }

.aui-forgotPassword__container .aui-forgotPassword__content {
  padding: 2.1em 1.1em 0; }

.aui-forgotPassword__container .aui-forgotPassword__title {
  font-size: 1.19em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  line-height: 24px;
  margin: 0 0 20px 5px; }

.aui-forgotPassword__container .input__container {
  margin: 0 0 15px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  cursor: text;
  box-sizing: border-box;
  border-radius: 5px; }
  .aui-forgotPassword__container .input__container input {
    width: 100%;
    border: 0;
    font-size: 15px; }
  .aui-forgotPassword__container .input__container input:focus {
    outline: none; }
  .aui-forgotPassword__container .input__container input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset; }

.aui-forgotPassword__container .buttons__container {
  text-align: center;
  padding: 0 0 10px; }
  .aui-forgotPassword__container .buttons__container button {
    cursor: pointer;
    padding: 12px 14px;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 5px;
    background: #7645c0;
    color: #fff;
    font-size: 12px; }

.aui-forgotPassword__container .aui-forgotPassword__footer {
  border-top: 1px solid #efefef;
  text-align: center;
  padding: 15px 0;
  cursor: pointer; }
  .aui-forgotPassword__container .aui-forgotPassword__footer span {
    font-size: 15px;
    line-height: 22px; }

.aui-modal {
  z-index: 2;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.aui-modal__overlay {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 8px; }

.aui-modal__content {
  background: #fff;
  z-index: 3;
  border-radius: 3px;
  width: 100%;
  max-width: 25em; }

